import React, {useState} from 'react';
import Home from "./Home";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Preview from "./Preview";
import './styles/app.css';
import Footer from "./Footer";
import Imprint from "./Imprint";
import Privacy from "./Privacy";
import Header from "./Header";

function App() {
    const [coverLetter, setCoverLetter] = useState<string[]>([]);
    const [error, setError] = useState<string[]>([]);

    return (
        <div className={'all'}>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path='/' element={<Home setCoverLetter={setCoverLetter} setError={setError}/>} />
                    <Route path='/preview' element={<Preview coverLetter={coverLetter} error={error}/>} />
                    <Route path='/imprint' element={<Imprint />} />
                    <Route path='/privacy' element={<Privacy />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;

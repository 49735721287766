import React, { useEffect, useState } from 'react';
import './styles/footer.css';
import {useNavigate} from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className={'footer'}>
            <div className={'footerItem'} onClick={() => navigate('/imprint')}>Impressum</div>
            <div className={'footerItem'} onClick={() => navigate('/privacy')}>Datenschutz</div>
        </div>
    );
};

export default Footer;
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './styles/home.css';
import TextareaAutosize from "react-textarea-autosize";

function Home(props: {setCoverLetter: (coverLetter: string[]) => void, setError: (error: string[]) => void}) {
    const [personInfo, setPersonInfo] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [requestingCoverLetter, setRequestingCoverLetter] = useState(false);
    const [allowOpenAiCommunication, setAllowOpenAiCommunication] = useState(false);
    const [notifyAllowOpenAiCommunicationMissing, setNotifyAllowOpenAiCommunicationMissing] = useState(false);

    const [missingInfo, setMissingInfo] = useState(false);

    const navigate = useNavigate();

    function initiateSubmit() {
        if (!requestingCoverLetter) {
            setRequestingCoverLetter(true);
        }
    }

    useEffect(() => {
        if (!requestingCoverLetter) {
            return;
        }
        console.log('1')
        if (!allowOpenAiCommunication) {
            setNotifyAllowOpenAiCommunicationMissing(true);
            setRequestingCoverLetter(false);
            return;
        }
        setNotifyAllowOpenAiCommunicationMissing(false);

        console.log('2')
        if (!jobDescription || !personInfo) {
            setMissingInfo(true);
            setRequestingCoverLetter(false);
            return;
        }
        setMissingInfo(false);

        console.log('3')
        let data = new FormData();
        data.append('personInfo', personInfo);
        data.append('jobDescription', jobDescription);

        fetch('/coverLetter', {method: 'POST', body: data})
            .then(response => response.json())
            .then(jsonResponse => {
                if ('coverLetter' in jsonResponse) {
                    props.setCoverLetter(jsonResponse.coverLetter)
                }
                if ('error' in jsonResponse) {
                    props.setError(jsonResponse.error)
                }
                navigate('/preview')
            })
            .catch(error => {
                console.log(error);
            });
        setRequestingCoverLetter(false);
    }, [requestingCoverLetter]);

    return (
        <div className={'outer'}>
            <label htmlFor="name">Informationen zu deiner Person:</label>
            <div className={'textField'}>
                <TextareaAutosize
                    id={'personInfo'}
                    value={personInfo}
                    onChange={e => setPersonInfo(e.target.value)}
                    className={''}
                    minRows={6}
                    style={{fontSize: '20px', maxWidth: '750px', width: '100%', borderColor: missingInfo && !personInfo ? 'red' : 'black'}}
                />
                <div className={'charCounter'}>{personInfo.length} / 250</div>
            </div>
            <br/>

            <label htmlFor="message">Stellenbeschreibung:</label>
            <div className={'textField'}>
                <TextareaAutosize
                    id={'jobDescription'}
                    value={jobDescription}
                    onChange={e => setJobDescription(e.target.value)}
                    className={''}
                    minRows={6}
                    style={{fontSize: '20px', maxWidth: '750px', width: '100%', borderColor: missingInfo && !personInfo ? 'red' : 'black'}}
                />
                <div className={'charCounter'}>{jobDescription.length} / 500</div>
            </div>

            <div className={'submitBtn'} onClick={initiateSubmit}>Bewerbung erstellen</div>
            <div
                className={'openAiCheckbox'}
                style={{border: notifyAllowOpenAiCommunicationMissing && !allowOpenAiCommunication ? '1px solid red' : 'none'}}
            >
                Der Dienst kommuniziert mit <a href={'https://openai.com/'}>openAi</a>. Um dein Bewerbungsschreiben zu erstellen, benötigen wir deine Einverständnis, die von dir angegebenen Daten an openAi zu versenden.
                <input
                    className={'checkbox'}
                    type={'checkbox'}
                    checked={allowOpenAiCommunication}
                    onChange={() => setAllowOpenAiCommunication(!allowOpenAiCommunication)}
                />
            </div>
        </div>
    );
}

export default Home;

import React, {useRef} from 'react';
import './styles/preview.css';


function Preview(props: {coverLetter: string[], error: string[]}) {
    const letterRef = useRef<HTMLDivElement>(null);


    function download() {
        let letterContent = ''
        if (letterRef.current) {
            letterContent = letterRef.current.innerHTML
        }
        let headers = {
            'Content-Type': 'application/json;odata=verbose'
        }
        let data = new FormData();
        data.append('letterContent', letterContent);

        fetch('/download', {method: 'POST', body: letterContent, headers: headers})
            .then(response => {
                response.blob().then(blob => {
                    console.log(blob)
                    // Create a download link
                    let downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = 'file.docx';
                    downloadLink.style.display = 'none';

                    // Append the link to the document body
                    document.body.appendChild(downloadLink);

                    // Trigger the download programmatically
                    downloadLink.click();

                    // Clean up
                    document.body.removeChild(downloadLink);
                    URL.revokeObjectURL(downloadLink.href);
                }).catch(e => {
                    console.log(e)
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    // function isWordFile(blob) {
    //     const reader = new FileReader();
    //
    //     return new Promise((resolve, reject) => {
    //         reader.onloadend = () => {
    //             const bytes = new Uint8Array(reader.result);
    //
    //             // Check the Word file signature
    //             const signature = [0x50, 0x4B, 0x03, 0x04]; // Signature for .docx files
    //             const fileSignature = bytes.slice(0, signature.length);
    //
    //             const isCorrectFormat = signature.every((byte, index) => byte === fileSignature[index]);
    //
    //             resolve(isCorrectFormat);
    //         };
    //
    //         reader.onerror = () => {
    //             reject(new Error('An error occurred while reading the file.'));
    //         };
    //
    //         // Read the Blob as an ArrayBuffer
    //         reader.readAsArrayBuffer(blob);
    //     });
    // }

    return (
        <div className={'outerDiv'}>
            <div className={'letterDiv'}>
                <div ref={letterRef} className={'innerDiv'} contentEditable>
                    {props.error.length > 0 && (
                        <div>
                            {props.error.map((letterParagraph: string, index) => (
                                <p key={index} className={'paragraph'}>{ letterParagraph }</p>
                            ))}
                        </div>
                    )}
                    {props.coverLetter.length > 0 && (
                        <div>
                            <p className={'paragraph'}>Sehr geehrte Damen und Herren,</p>
                            {props.coverLetter.map((letterParagraph: string, index) => (
                                <p key={index} className={'paragraph'}>{ letterParagraph }</p>
                            ))}

                            <p className={'paragraph'}><b>Mit freundlichen Grüßen,</b></p>
                            <p className={'paragraph'}>Max Mustermann</p>
                        </div>
                    )}
                </div>
            </div>
            <div className={'downloadBtn'} onClick={download}>Download</div>
        </div>
    );
}

export default Preview;

import React, { useEffect, useState } from 'react';
import './styles/header.css';
import {useNavigate} from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    return (
        <div className={'header'}>
            <div className={'logo'} onClick={() => navigate('/')}></div>
        </div>
    );
};

export default Header;
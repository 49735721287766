import React, { useEffect, useState } from 'react';
import './styles/imprint.css';
import {useNavigate} from "react-router-dom";

const Imprint = () => {
    const navigate = useNavigate();

    return (
        <div className={'imprint'}>
            <p><strong>Impressum</strong></p>
            <p>Anbieter:<br/>Timo Mertin<br/>Florastraße 11<br/>60487 Frankfurt am Main</p>
            <p>Kontakt:<br/>Telefon: 0176/51959773<br/>E-Mail: usecoverletter@proton.me</p>
            <p>&nbsp;</p>
        </div>
    );
};

export default Imprint;